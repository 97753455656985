import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import '../components/layout.css';
import 'normalize.css';

import Header from '../components/header';
import LogoCover from '../components/LogoCover';
import Menu from '../components/menu';
import SEO from '../components/seo';
import { linkResolver } from '../utils/linkResolver';
import { theme } from '../constants/theme';
import { Arrow } from '../images/arrow';
import Wordmark from '../images/wordmark';
import useHomeIntroSequence, { POSES } from '../hooks/useHomeIntroSequence';
import useCircularScroll from '../hooks/useCircularScroll';

export const query = graphql`
  query Hompage($id: String){
    prismic {
      allHomepages(id: $id){
        edges {
          node {
            _meta {
              lang
            }
            cta_url {
              _linkType
            }
            cta_text
            text
            projects {
              position
              project {
                ... on PRISMIC_News {
                  title
                  featured
                  listing_image
                  _meta {
                    lang
                    type
                    uid
                  }
                }
                ... on PRISMIC_Project {
                  listing_image
                  listing_video {
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                      size
                      name
                    }
                  }
                  title
                  _meta {
                    lang
                    type
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const ProjectsListItem = React.memo(({ project, alignment }) => {
  if (!project || !project.listing_image) {
    return null;
  }

  const aspectRatio = (
    project.listing_image.dimensions.height
    / project.listing_image.dimensions.width
  );

  return (
    <ProjectContainer className={`${alignment}`}>
      <Link to={linkResolver(project._meta)} style={{ paddingTop: `${aspectRatio * 100}%` }}>

        { project.listing_video && project.listing_video.url ? (
          <video id="background-video" autoPlay loop muted playsInline>
            <source src={project.listing_video.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            alt={project.listing_image && project.listing_image.alt}
            src={project.listing_image && project.listing_image.url}
          />
        )}

      </Link>
      {project.title && (
        <div className="caption">
          {project.title}
        </div>
      )}
    </ProjectContainer>
  );
});

const HomePage = ({ data, location }) => {
  const page = data.prismic.allHomepages.edges[0];

  const homepage = page && page.node;

  const homeRef = useRef(null);

  // Reset scroll initially, to ensure nothing is overlapping.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    pose, goToNextPose, isDone, skipIntro,
  } = useHomeIntroSequence({
    ref: homeRef,
  });

  const { innerRef, CircularScrollDebug } = useCircularScroll({
    enabled: isDone,
  });

  const [navOpen, setNavOpen] = useState(false);

  const onLogoCoverFinish = useCallback(() => {
    goToNextPose();
  }, [goToNextPose]);

  const onHomepageContainerClick = useCallback(() => {
    skipIntro();
  }, [skipIntro]);

  if (!page || !page.node) {
    return null;
  }

  if (!homepage) {
    return null;
  }

  const {
    _meta: { lang },
  } = homepage;

  return (
    <>
      <Menu lang={lang} onClose={() => setNavOpen(false)} open={navOpen} location={location} />
      <HomepageContainer ref={homeRef} className={`${pose}`} onClick={onHomepageContainerClick}>
        <SEO title="Home" htmlClasses="home" description={homepage.text[0].text} />
        <ContentContainer className="fixed-content">
          <LogoCover enabled={pose === POSES.POSE_INITIAL} onFinish={onLogoCoverFinish} />
          <HomeIntro>
            <Wordmark />
          </HomeIntro>
          <HomeHeader>
            <Header color="white" lang={lang} nav={{ setNavOpen }} isHome />
          </HomeHeader>
          <HeroContainer>
            <Content>
              <ContentCopy>{homepage.text[0].text}</ContentCopy>
              <ContentCopyCta to={linkResolver({ lang, type: 'about' })}>
                {homepage.cta_text[0].text}
                {' '}
                <Arrow />
              </ContentCopyCta>
            </Content>
          </HeroContainer>
        </ContentContainer>
        <ProyectosContainer ref={innerRef}>
          {CircularScrollDebug}
          <ul>
            {homepage.projects && homepage.projects.map((projectItem) => (
              <ProjectsListItem
                key={projectItem.project && projectItem.project._meta.uid}
                alignment={projectItem.position}
                project={projectItem.project}
              />
            ))}
          </ul>
        </ProyectosContainer>
      </HomepageContainer>
    </>
  );
};

const HomepageContainer = styled.div`
  background-color: ${theme.colors.red};
  color: #fff;
  position: relative;
  z-index: 0;
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }
`;

const ContentContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const HomeIntro = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
  font-size: 42px;
  text-transform: uppercase;
  color: #fff;
  transition: transform 2s, opacity 0s;
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  opacity: 0;

  .pose-initial & {
    transform: translateY(100%);
  }

  .pose-wordmark & {
    transform: translateY(0%);
    transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
    opacity: 1;
  }

  .pose-normal & {
    transform: translateY(-100%);
    transition-delay: 0s, 2s;
  }

  svg {
    @media ${theme.breakpoints.size.xl} {
      width: 215px;
    }
    @media ${theme.breakpoints.size.l} {
      width: 176px;
    }
    @media ${theme.breakpoints.size.xs} {
      width: 150px;
    }
  }
`;

const HomeHeader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  transition: transform 2s ease, opacity 2s ease;
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
  z-index: 1;

  .pose-normal & {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  }
`;

const HeroContainer = styled.div`
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 78px;
  color: white;
  transform: translateY(100%);
  transition: transform 2s cubic-bezier(0.860, 0.000, 0.070, 1.000);

  .pose-normal & {
    transform: translateY(0%);
  }

  @media ${theme.breakpoints.size.m} {
    padding: 0 40px;
  }

  @media ${theme.breakpoints.size.s} {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  color: white;
  text-align: center;
  position: relative;
`;

const ContentCopy = styled.h1`
  margin: 0 auto 35px auto;
  font-weight: 400;

  font-size: 90px;
  line-height: 100px;
  padding: 0 180px;

  @media ${theme.breakpoints.size.xl} {
    font-size: 68px;
    line-height: 75px;
    padding: 0 140px;
  }
  @media ${theme.breakpoints.size.l} {
    font-size: 55px;
    line-height: 62px;
    padding: 0 120px;
  }
  @media ${theme.breakpoints.size.m} {
    padding: 0 40px;
  }
  @media ${theme.breakpoints.size.s} {
    font-size: 30px;
    line-height: 38px;
    padding: 0 20px;
  }
`;

const ContentCopyCta = styled(Link)`
  color: white;
  text-decoration: none;
  position: relative;

  svg {
    rect {
      fill: white;
    }
    path {
      stroke: white;
    }
  }
`;

const ProyectosContainer = styled.div`
  width: 100%;
  display: flex;
  opacity: 0;

  .pose-normal & {
    opacity: 1;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: calc(120vh + 100px);
    padding-bottom: calc(120vh + 100px);
    width: 100%;
    display: flex;
    flex-direction: column;

    .has-window-height & {
      padding-top: calc(var(--window-height) * 1.2 + 100px);
      padding-bottom: calc(var(--window-height) * 1.2 + 100px);
    }
  }

  li:not(:first-child) {
    margin-top: 80vh;
  }

  .center {
    align-self: center;
  }

  .left {
    align-self: flex-start;
  }

  .right {
    align-self: flex-end;
  }

`;

const ProjectContainer = styled.li`
  width: 45%;

  @media ${theme.breakpoints.size.s} {
    width: 60%;
  }

  > a {
    padding-top: 100%;
    height: 0;
    display: block;
    position: relative;
    overflow: hidden;
    transform: translateZ(0px);
    z-index: 2;
  }

  img {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  video {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
  }

  .caption {
    position: fixed;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 40px;
    font-size: 16px;
    text-align: center;
    pointer-events: none;
    opacity: 0;

    @media ${theme.breakpoints.size.s} {
      display: none;
    }
  }

  &:hover .caption {
    opacity: 1;
  }
`;

export default HomePage;
