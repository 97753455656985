import { useEffect } from 'react';
import { useRafState } from 'react-use';

const isClient = typeof window === 'object';

const useWindowHeight = (initialHeight = Infinity) => {
  const [state, setState] = useRafState({
    height: isClient ? window.screen.availHeight : initialHeight,
  });

  useEffect(() => {
    if (isClient) {
      const handler = () => {
        setState({
          height: window.screen.availHeight,
        });
      };

      if (state.height !== window.screen.availHeight) {
        setState({
          height: window.screen.availHeight,
        });
      }

      window.addEventListener('resize', handler);

      return () => {
        window.removeEventListener('resize', handler);
      };
    }

    return () => {};
  }, [state.height, setState]);

  return state;
};

export default useWindowHeight;
