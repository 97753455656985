/* eslint-disable react/jsx-filename-extension */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import styled from 'styled-components';

import useWindowHeight from './useWindowHeight';
import useMeasure from './useMeasureDirty';

const SCROLLING_UP = 'up';
const SCROLLING_DOWN = 'down';
const RESTART_TIMEOUT_MS = 300;

export default function useCircularScroll({
  enabled = true,
  auto = true,
  debug = false,
}) {
  const [userHasScrolled, userHasScrolledSet] = useState(false);
  // eslint-disable-next-line prefer-const
  let [isAutoScrolling, isAutoScrollingSet] = useState(auto);
  const restartAutoScrollTimeout = useRef(null);
  const [isTouching, isTouchingSet] = useState(false);
  let autoScrollInterval;
  const minDelta = 0.5;
  const prevTime = useRef(null);
  const pendingScrollTop = useRef(0);
  const applyingScrollTop = useRef(0);
  const appliedScrollTop = useRef(0);
  const scrollDir = useRef(SCROLLING_DOWN);
  let isScrolling = false;

  // Force disable `isAutoScrolling` if `auto` option is off
  if (!auto && isAutoScrolling) {
    isAutoScrolling = false;
  }

  /**
   * Key measurements down the page, to handle circular scroll
   */
  const innerRef = useRef(null);
  const { height: scrollHeight } = useMeasure(innerRef);
  const { height: windowHeight } = useWindowHeight();
  const viewportSafeGap = windowHeight * 0.2;
  const start = viewportSafeGap;
  const end = scrollHeight - viewportSafeGap - windowHeight;
  const startOrigin = viewportSafeGap + 100;
  const endOrigin = end - 100;

  /**
   * Track the window height to a CSS variable whenever it changes.
   * Keeps dimensions 100% consistent during mobile scroll
   */
  useEffect(() => {
    if (windowHeight !== Infinity) {
      document.body.style.setProperty('--window-height', `${windowHeight}px`);
      document.body.classList.add('has-window-height');
    } else {
      document.body.style.removeProperty('--window-height');
      document.body.classList.remove('has-window-height');
    }
  }, [windowHeight]);

  const autoScrollTimeoutFn = useCallback(() => {
    isAutoScrollingSet(true);
  }, []);

  /**
   * Watches to restart auto-scroll
   */
  const checkRestartAutoScroll = useCallback(() => {
    if (restartAutoScrollTimeout.current) {
      clearTimeout(restartAutoScrollTimeout.current);
      restartAutoScrollTimeout.current = setTimeout(autoScrollTimeoutFn, RESTART_TIMEOUT_MS);
    }
  }, [restartAutoScrollTimeout, autoScrollTimeoutFn]);

  /**
   * Temporarily turns off auto-scroll
   */
  const manualScroll = useCallback(() => {
    if (isAutoScrolling) {
      isAutoScrollingSet(false);

      if (!userHasScrolled) {
        userHasScrolledSet(true);
      }
    }
  }, [isAutoScrolling, userHasScrolled]);

  const progScroll = (px) => {
    isScrolling = true;
    window.scrollTo(0, px);
    isScrolling = false;
    pendingScrollTop.current = px;
    applyingScrollTop.current = px;
  };

  const goToOrigin = () => {
    const y = parseInt(startOrigin, 10);
    progScroll(y);
  };

  const onWindowWheel = useCallback(() => {
    manualScroll();
  }, [manualScroll]);

  const onTouchStart = useCallback(() => {
    isTouchingSet(true);
    checkRestartAutoScroll();
  }, [checkRestartAutoScroll]);

  const onTouchMove = useCallback(() => {
    if (!isTouching) {
      isTouchingSet(true);
    }
    manualScroll();
    checkRestartAutoScroll();
  }, [isTouching, manualScroll, checkRestartAutoScroll]);

  const onTouchEnd = useCallback(() => {
    isTouchingSet(false);
  }, []);

  /**
   * Initialize the scroll position
   */
  useIsomorphicLayoutEffect(() => {
    goToOrigin();
  }, []);

  useIsomorphicLayoutEffect(() => {
    let isResetting = false;

    // Don't use this hook until the measurements are ready,
    // or if the page isn't long enough to warrant it
    if (!scrollHeight || !windowHeight || !(scrollHeight > windowHeight * 2)) {
      return;
    }

    if (!enabled) {
      return;
    }

    /**
     * If it is currently being manually scrolled, prepare to go auto (will be cancelled elsewhere)
     */
    if (!isAutoScrolling && !restartAutoScrollTimeout.current) {
      restartAutoScrollTimeout.current = setTimeout(autoScrollTimeoutFn, RESTART_TIMEOUT_MS);
    }

    const onWindowScroll = () => {
      checkRestartAutoScroll();

      if (isResetting) {
        scrollDir.current = SCROLLING_DOWN;
        appliedScrollTop.current = startOrigin;
        isResetting = false;
      } else {
        if (window.scrollY > appliedScrollTop.current) {
          scrollDir.current = SCROLLING_DOWN;
        } else if (window.scrollY < appliedScrollTop.current) {
          scrollDir.current = SCROLLING_UP;
        }
        appliedScrollTop.current = window.scrollY;
        if (!isAutoScrolling) {
          applyingScrollTop.current = window.scrollY;
          pendingScrollTop.current = window.scrollY;
        }
      }
    };

    const checkCircularEdges = () => {
      if (scrollDir.current === SCROLLING_DOWN) {
        /**
         * If the end has been reached, trigger a 'reset'
         */
        if (appliedScrollTop.current >= end) {
          isResetting = true;
          goToOrigin();
          return true;
        }
      } else if (appliedScrollTop.current <= start) {
        progScroll(parseInt(endOrigin, 10));
        return true;
      }
      return false;
    };

    let stopLoop = false;

    const loop = () => {
      let timeDiff;
      const now = performance.now();

      if (prevTime.current == null) {
        timeDiff = 1;
      } else {
        timeDiff = now - prevTime.current;
      }

      const speed = 0.0025 * 20 * timeDiff;

      prevTime.current = now;

      if (stopLoop) {
        return;
      }
      if (!isResetting && !isScrolling) {
        if (!checkCircularEdges()) {
          if (isAutoScrolling) {
            const newY = pendingScrollTop.current + speed;
            if (Math.abs(newY - appliedScrollTop.current) >= minDelta) {
              progScroll(newY);
            }
            pendingScrollTop.current = newY;
          }
        }
      }
      window.requestAnimationFrame(loop);
    };

    if (!isTouching) {
      window.requestAnimationFrame(loop);
    }

    window.addEventListener('scroll', onWindowScroll);
    window.addEventListener('wheel', onWindowWheel);

    if ('ontouchstart' in window) {
      window.addEventListener('touchstart', onTouchStart);
      window.addEventListener('touchmove', onTouchMove);
      window.addEventListener('touchend', onTouchEnd);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (autoScrollInterval) {
        clearInterval(autoScrollInterval);
      }

      window.cancelAnimationFrame(loop);
      stopLoop = true;

      window.removeEventListener('scroll', onWindowScroll);
      window.removeEventListener('wheel', onWindowWheel);

      if ('ontouchstart' in window) {
        window.removeEventListener('touchstart', onTouchStart);
        window.removeEventListener('touchmove', onTouchMove);
        window.removeEventListener('touchend', onTouchEnd);
      }
    };
  }, [
    enabled,
    windowHeight,
    scrollHeight,
    userHasScrolled,
    viewportSafeGap,
    startOrigin,
    endOrigin,
    start,
    end,
    auto,
    isAutoScrolling,
    isTouching,
  ]);

  const CircularScrollDebug = debug ? (
    <>
      <DebugLine style={{ top: start }}>Start</DebugLine>
      <DebugLine style={{ top: startOrigin }}>Start - origin</DebugLine>
      <DebugLine style={{ top: end - 100 }}>End - origin</DebugLine>
      <DebugLine style={{ top: end, transform: 'translate(0, 100%)' }}>End</DebugLine>
    </>
  ) : null;

  return {
    innerRef,
    windowHeight,
    CircularScrollDebug,
  };
}

const DebugLine = styled.div`
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  pointer-events: none;
  border-top: 1px solid;
`;
