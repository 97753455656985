import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

// const EASE_IN_OUT_QUINT = (t) => (t < 0.5
//   ? 16 * t * t * t * t * t
//   : 1 + 16 * (--t) * t * t * t * t);

// eslint-disable-next-line no-plusplus,no-param-reassign
const EASE_OUT_QUINT = (t) => (1 + (--t) * t * t * t * t);

const poses = [
  {
    name: 'closed',
    interval: 1000,
    points: [
      '0,0 46,0 50,100 0,100',
      '45,0 55,0 50,100',
      '54,0 100,0 100,100 50,100',
    ],
  },
  {
    name: 'v',
    interval: 2500,
    finishAfter: true,
    points: [
      '0,0 0,0 20,100 0,100',
      '45,0 55,0 50,100',
      '100,0 100,0 100,100 80,100',
    ],
  },
  {
    name: 'closed',
    points: [
      '0,0 0,0 0,100 0,100',
      '50,0 50,0 50,0',
      '100,0 100,0 100,100 100,100',
    ],
  },
];

const createShapeInterpolation = (shapeIndex) => ({
  range: [...Array(poses.length).keys()],
  output: poses.map((pose) => pose.points[shapeIndex]),
});

const shapeInterpolations = [
  createShapeInterpolation(0),
  createShapeInterpolation(1),
  createShapeInterpolation(2),
];

const LogoCover = React.memo(({
  enabled = true,
  onFinish = () => {},
}) => {
  const [poseIndex, poseIndexSet] = useState(enabled ? 0 : poses.length - 1);
  const pose = poses[poseIndex];
  const isLastPose = poseIndex === poses.length - 1;

  useEffect(() => {
    if (!enabled && poseIndex !== poses.length - 1) {
      poseIndexSet(poses.length - 1);
    }
  }, [enabled, poseIndex]);

  useEffect(() => {
    let timeout;
    if (enabled && !isLastPose) {
      timeout = setTimeout(() => {
        poseIndexSet(poseIndex + 1);
        if (pose.finishAfter) {
          onFinish();
        }
      }, pose.interval || 0);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [poseIndex, enabled, onFinish, isLastPose, pose]);

  const { i } = useSpring({ config: { duration: 2000, easing: EASE_OUT_QUINT }, i: poseIndex });

  return (
    <RootNode>
      <svg viewBox="0 0 100 100" width="100" height="100" preserveAspectRatio="none">
        <g fill="#fff">
          <animated.polygon
            points={i.interpolate(shapeInterpolations[0])}
          />
          <animated.polygon
            points={i.interpolate(shapeInterpolations[1])}
          />
          <animated.polygon
            points={i.interpolate(shapeInterpolations[2])}
          />
        </g>
      </svg>
    </RootNode>
  );
});

const RootNode = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0px);

  svg {
    width: 100%;
    height: 100%;
  }
`;

export default LogoCover;
