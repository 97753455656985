/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const Wordmark = () => (
  <svg width="260" height="63" viewBox="0 0 260 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Espacio V</title>
    <path d="M109.244 21.8473H109.422L113.536 34.2137H105.041L109.244 21.8473ZM94.1763 45.7558H101.137L103.239 39.5725H115.327L117.428 45.7558H124.612L113.403 15.575H105.385L94.1763 45.7558Z" fill="currentColor" />
    <path d="M9.24072 45.7558H32.315V40.1853H16.024V32.9437H28.9011V27.7297H16.024V21.1454H31.8368V15.575H9.24072V45.7558Z" fill="currentColor" />
    <path d="M37.0078 37.155C37.0078 42.8591 41.3002 46.2794 49.5291 46.2794C57.8914 46.2794 62.5397 42.3244 62.5397 36.5422C62.5397 24.432 44.1914 30.0025 44.1914 23.5964C44.1914 21.2234 46.5155 19.9534 49.6625 19.9534C52.6872 19.9534 54.9557 21.268 54.9557 23.7636H61.5277C61.5277 18.4939 56.9685 15.0291 49.6625 15.0291C42.1342 15.0291 37.486 19.0175 37.486 24.2872C37.486 36.5311 55.6563 30.9495 55.6563 37.3221C55.6563 39.8622 53.3322 41.3551 49.5291 41.3551C45.9818 41.3551 43.5687 39.8622 43.5687 37.1438H37.0078V37.155Z" fill="currentColor" />
    <path d="M83.0116 15.575H69.2227V45.7558H76.0059V36.1857H83.0116C90.362 36.1857 94.4431 31.7962 94.4431 25.7021C94.4431 19.6971 90.3732 15.575 83.0116 15.575ZM82.0998 30.8826H76.0059V20.878H82.0998C85.6471 20.878 87.4819 22.8054 87.4819 25.8358C87.4819 28.8661 85.6471 30.8826 82.0998 30.8826Z" fill="currentColor" />
    <path d="M126.402 30.6599C126.402 39.5726 131.873 46.2795 141.604 46.2795C149.221 46.2795 154.214 42.2019 155.315 35.5285H148.576C147.697 38.7705 145.64 40.5308 141.737 40.5308C136.611 40.5308 133.375 36.4086 133.375 30.6599C133.375 24.8666 136.488 20.789 141.693 20.789C145.329 20.789 147.697 22.5493 148.576 25.7913H155.315C154.225 19.129 149.31 15.0403 141.693 15.0403C132.274 15.0403 126.402 21.8028 126.402 30.6599Z" fill="currentColor" />
    <path d="M168.148 15.575H161.364V45.7558H168.148V15.575Z" fill="currentColor" />
    <path d="M190.155 15.0403C180.569 15.0403 174.475 21.6691 174.475 30.6599C174.475 39.6618 180.558 46.2795 190.155 46.2795C199.573 46.2795 205.834 39.6506 205.834 30.6599C205.823 21.6691 199.562 15.0403 190.155 15.0403ZM190.155 40.5308C184.95 40.5308 181.436 36.4977 181.436 30.6599C181.436 24.822 184.939 20.789 190.155 20.789C195.37 20.789 198.873 24.9557 198.873 30.6599C198.862 36.364 195.359 40.5308 190.155 40.5308Z" fill="currentColor" />
    <path d="M229.976 45.7558H238.116L248.935 15.575H241.974L234.224 39.4834H234.046L226.384 15.575H219.2L229.976 45.7558Z" fill="currentColor" />
  </svg>
);

export default Wordmark;
