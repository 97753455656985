import { useCallback, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { useGlobalState } from '../contexts/globalState';
import useBodyScrollLock from './useBodyScrollLock';

export const POSES = {
  POSE_INITIAL: 'pose-initial',
  POSE_WORDMARK: 'pose-wordmark',
  POSE_NORMAL: 'pose-normal',
};

const poseOrder = [
  POSES.POSE_INITIAL,
  POSES.POSE_WORDMARK,
  POSES.POSE_NORMAL,
];

export default function useHomeIntroSequence({ ref, doneDelay = 1500 }) {
  const { homepageIntroWatched, homepageIntroWatchedSet } = useGlobalState();
  const [pose, setPose] = useState(homepageIntroWatched ? POSES.POSE_NORMAL : POSES.POSE_INITIAL);
  const isLastPose = poseOrder.indexOf(pose) === poseOrder.length - 1;
  const [isDone, isDoneSet] = useState(false);
  const posePrev = usePrevious(pose);

  useEffect(() => {
    if (pose === POSES.POSE_NORMAL) {
      homepageIntroWatchedSet(true);
    }
  }, [pose, homepageIntroWatchedSet]);

  useBodyScrollLock({
    locked: !isDone,
    ref,
  });

  useEffect(() => {
    if (pose === POSES.POSE_WORDMARK) {
      const timeout = setTimeout(() => {
        setPose(POSES.POSE_NORMAL);
      }, 2000);
      return () => {
        clearInterval(timeout);
      };
    }
    return () => {};
  }, [pose]);

  const goToNextPose = useCallback(() => {
    if (!isLastPose) {
      const index = poseOrder.indexOf(pose);
      setPose(poseOrder[index + 1]);
    }
  }, [pose, isLastPose]);

  useEffect(() => {
    if (isLastPose) {
      const timeout = setTimeout(() => {
        isDoneSet(true);
      }, posePrev ? doneDelay : 0);
      return () => {
        clearTimeout(timeout);
      };
    }
    isDoneSet(false);
    return () => {};
  }, [isLastPose, doneDelay, posePrev]);

  const skipIntro = useCallback(() => {
    if (!isLastPose) {
      setPose(poseOrder[poseOrder.length - 1]);
    }
  }, [isLastPose]);

  return {
    pose,
    goToNextPose,
    skipIntro,
    isDone,
  };
}
